.posts {
                width: 905px;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                margin-top: 40px;
                @media screen and (max-width: 920px) {
                    padding-left: 15px;
                    padding-right: 15px;
                    width: 350px; }
                @media screen and (max-width: 480px) {
                     margin-top: 100px; }
                @media screen and (max-width: 350px) {
                    padding-left: 0px;
                    padding-right: 0px;
                    width: 100%; }
                .post-1 {
                    width: 100%;
                    height: 170px;
                    box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.25);
                    border-radius: 15px;
                    position: relative;
                    background-color: #ffffff;
                    @media screen and (max-width: 920px) {
                        display: flex;
                        flex-wrap: wrap;
                        height: 400px; }
                    @media screen and (max-width: 350px) {
                        width: 100%; }
                    &:hover {
                        background-color: #F1DFF4; }
                    .post-1-img {
                        width: 240px;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        @media screen and (max-width: 920px) {
                            position: relative;
                            left: initial;
                            display: inline-block;
                            vertical-align: top;
                            height: 45%;
                            flex-wrap: wrap;
                            width: 100%; }
                        @media screen and (max-width: 350px) {
                            width: 100%; }
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 15px 0px 0px 15px;
                            @media screen and (max-width: 920px) {
                                border-radius: 15px 15px 0px 0px; }
                            @media screen and (max-width: 350px) {
                                width: 100%; } } }
                    .post-1-text {
                        position: absolute;
                        right: 0;
                        padding-top: 30px;
                        height: 100%;
                        width: 625px;
                        @media screen and (max-width: 920px) {
                            right: initial;
                            bottom: 0;
                            padding-top: 0px;
                            height: 55%;
                            padding-left: 20%;
                            width: 250px;
                            text-align: center; }
                        @media screen and (max-width: 350px) {
                            width: 100%;
                            padding-left: 0px; }
                        h3 {
                            font-family: Arial sans-serif futurademi;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 19px;
                            @media screen and (max-width: 920px) {
                                margin-top: 10px; }
                            @media screen and (max-width: 480px) {
                                font-size: 19px; }
                            @media screen and (max-width: 350px) {
                                width: 200px;
                                margin-left: auto;
                                margin-right: auto; } }

                        p {
                            width: 420px;
                            display: block;
                            height: 40px;
                            font-family: Arial futuralight;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 15px;
                            line-height: 13px;
                            @media screen and (max-width: 920px) {
                                margin-left: -15%;
                                width: 250px;
                                height: 70px;
                                font-size: 17px; }
                            @media screen and (max-width: 480px) {
                                height: 50px;
                                line-height: 20px;
                                margin-top: 5px; }
                            @media screen and (max-width: 350px) {
                                margin-left: auto;
                                margin-right: auto; } }

                        .blog-link {
                            width: 150px;
                            margin-top: 20px;
                            @media screen and (max-width: 920px) {
                                margin-left: 7%;
                                position: absolute;
                                bottom: 20px; }
                            @media screen and (max-width: 350px) {
                                margin-left: 25%; }
                            a {
                                width: 150px;
                                display: flex;
                                @media screen and (max-width: 350px) {
                                    margin-left: auto;
                                    margin-right: auto; }
                                .read-more {
                                    font-family: Arial futuralight;
                                    font-style: normal;
                                    font-weight: bold;
                                    color: #000000;
                                    width: 145px;
                                    @media screen and (max-width: 480px) {
                                        font-size: 14px;
                                        width: 135px;
                                        margin-left: auto;
                                        margin-right: auto; } }
                                .read-more-arrow {
                                    width: 6px;
                                    height: 6px;
                                    border-left: 2px solid #000000;
                                    border-top: 2px solid #000000;
                                    transform: rotate(135deg);
                                    margin-top: 7px;
                                    @media screen and (max-width: 480px) {
                                        margin-top: 5px; } } } } } } }
