footer {
        background-color: #3B373C;
        overflow-x: hidden;
        overflow-y: hidden;
        @media screen and (max-width: 800px) {
            position: relative;
            height: 600px; }
        .footer-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: 35px;
            padding-bottom: 35px;
            max-width: 905px;
            margin-left: auto;
            margin-right: auto;
            height: inherit;
            @media screen and (max-width: 800px) {
                width: 350px;
                margin-right: auto;
                margin-left: auto; }
            .footer-logo {
                margin-top: 20px;
                @media screen and (max-width: 800px) {
                    position: absolute;
                    top: 180px;
                    width: 350px;
                    border-bottom: 1px solid #ffffff;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    text-align: center;
                    height: 150px; }
                @media screen and (max-width: 350px) {
                    left: 50%;
                    margin-left: -175px; }
                img {
                    width: 265px; } }
            .footer-text {
                @media screen and (max-width: 800px) {
                    width: 250px;
                    position: absolute;
                    bottom: 3%;
                    text-align: center;
                    height: 200px;
                    text-align: center; }
                p {
                    width: 335px;
                    margin-top: 35px;
                    font-family: Arial, futuralight;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 13px;
                    color: #6C6C6C; } }
            .footer-nav {
                @media screen and (max-width: 800px) {
                    width: 350px;
                    position: absolute;
                    display: inline-block;
                    top: 15px;
                    text-align: center;
                    border-bottom: 1px solid #ffffff;
                    padding-bottom: 20px;
                    height: 180px; }
                @media screen and (max-width: 350px) {
                    left: 50%;
                    margin-left: -175px; }
                ul {
                    li {
                        font-family: Arial, futuralight;
                        font-style: normal;
                        font-weight: normal;
                        a {
                            color: #FFFFFF;
                            font-size: 16px;
                            line-height: 29px; } } } }
            .footer-social {
                    @media screen and (max-width: 800px) {
                        position: absolute;
                        width: 350px;
                        display: flex;
                        justify-content: space-around;
                        bottom: 31%;
                        height: 49px; }
                    @media screen and (max-width: 480px) {
                        width: 250px;
                        left: 50%;
                        margin-left: -125px; }
                    a {
                        .footer-social-helper {
                            display: none; }
                        .footer-social-inst {
                            width: 39px;
                            height: 39px;
                            background-image: url('../img/png/sprite.png');
                            background-position: -466px -28px; }
                        &:hover {
                            .footer-social-helper {
                                display: block;
                                position: absolute;
                                right: 5%;
                                width: 80px;
                                height: 20px;
                                text-align: center;
                                border: 1px solid #000000;
                                background: #F1DFF4;
                                border-radius: 5px;
                                color: red; } } }

                    a {
                        .footer-social-facebook {
                            position: relative;
                            margin-top: 10px;
                            width: 39px;
                            height: 39px;
                            background-image: url('../img/png/sprite.png');
                            background-position: -523px -31px;
                            @media screen and (max-width: 800px) {
                                margin-top: 0px; } }
                        &:hover {
                            .footer-social-helper {
                                display: block;
                                position: absolute;
                                right: 5%;
                                width: 80px;
                                height: 20px;
                                text-align: center;
                                border: 1px solid #000000;
                                background: #F1DFF4;
                                border-radius: 5px;
                                color: #000000; } } }
                    a {
                        .footer-social-mail {
                            margin-top: 10px;
                            width: 39px;
                            height: 39px;
                            background-image: url('../img/png/sprite.png');
                            background-position: -569px -30px;
                            @media screen and (max-width: 800px) {
                                margin-top: 0px; } }
                        &:hover {
                            .footer-social-helper {
                                display: block;
                                position: absolute;
                                right: 8%;
                                width: 80px;
                                height: 20px;
                                text-align: center;
                                border: 1px solid black;
                                background: #F1DFF4;
                                border-radius: 5px;
                                color: #000000;
                                z-index: 10; } } } } } }
