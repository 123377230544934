@import 'adaptiv/ad_main';

// Only for zzz.com.ua hosting
.cbalink,
body > div:first-child {
    display: none; }

// Only for zzz.com.ua hosting

* {
    box-sizing: border-box; }
a {
    text-decoration: none; }
ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none; }
h2,h3,h4 {
    margin: 0px;
    text-transform: uppercase; }
body {
    margin: 0px;
    @import 'adaptiv/button';
    @import 'imports/main/header';
    article {
        .collage-section {
            img {
                margin-top: 45px;
                @media screen and (max-width: 480px) {
                    margin-top: 45px; }
                width: 100%; } }
        @import 'imports/main/about';

        @import 'imports/main/last-in-blog'; }

    @import 'imports/main/footer'; }





