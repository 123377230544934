$mobileWidth: 480px;

img {
	max-width: 100%; }
header {
	width: 100%;
	height: auto;
	box-shadow: none;
	position: relative;
	background: #ffffff;
	@media all and (max-width: $mobileWidth) {
        z-index: 1000;
        height: 40px;
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1); } }


.mobile_logo {
	display: none;
	@media all and (max-width: $mobileWidth) {
		display: flex;
		justify-content: center;
		align-items: center;
		// display: block
		margin: auto;
		width: 130px;
		height: 40px;
		& a {
			// height: 35px
			// display: inline-block
			display: block; } } }

.logo {
	width: 350px;
	height: auto;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	display: block;
	@media all and (max-width: $mobileWidth) {
		display: none; } }
.plug {
	width: 100%;
	height: 52px; }
.menu {
	width: 660px;
	height: auto;
	padding: 15px 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	margin: auto;
	list-style: none;
	font-family: arial, futuralight, sans-serif;
	a {
		text-decoration: none;
		color: #000;
		font-size: 16px;
		font-family: arial, futuralight, sans-serif;
		text-transform: uppercase;
		@media all and (min-width: 481px) {
			&:after {
				content: "";
				display: block;
				height: 2px;
				background-color: #AC8EB1;
				width: 0%;
				margin-top: 2px;
				transition: width .3s ease-in-out; }
			&:hover:after {
				width: 100%; }
			&:focus:after {
				width: 100%; } }
		@media all and (max-width: $mobileWidth) {
			width: 100%;
			height: 10px;
			color: #666;
			font-size: 12px;
			font-weight: bold;
			&:first-child {
				li {
					border-top: 1px solid #ddd; } } }

		li {
			@media all and (max-width: $mobileWidth) {
				background: #fff;
				padding: 15px 0;
				border-bottom: 1px solid #ddd;
				&:hover {
					background: #f1dff4; }
				&:active {
					background: #f1dff4; } } } }



	@media all and (max-width: 660px) and (min-width: 481px) {
		width: 100%;
		padding: 15px; }
	@media all and (max-width: $mobileWidth) {
		display: none;
		width: 100%;
		text-align: center;
		position: fixed;
		top: 40px;
		left: 0;
		padding: 0; } }


.hamburger {
	display: none;
	@media all and (max-width: $mobileWidth) {
		display: block;
		position: absolute;
		top: 5px;
		right: 10px;
		background: url('../img/png/menu.png');
		width: 30px;
		height: 30px;
		background-size: contain;
		background-repeat: no-repeat;
		cursor: pointer;
		z-index: 100000000; } }
.cross {
	display: none;
	@media all and (max-width: $mobileWidth) {
		background: url('../img/png/close.png');
		width: 20px;
		height: 20px;
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
		z-index: 10; } }
nav {
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	background: #fff;
	box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
	&.nav-fixed {
		@media all and (min-width: $mobileWidth) {
			position: fixed;
			top: 0;
			bottom: initial;
			align-content: center;
			z-index: 1000; } } }
.nav-fixed-replacer {
	width: 100%;
	height: 52px;
	@media screen and (max-width: $mobileWidth) {
        display: none; } }
.menu {
	margin: 0 auto; }
header {
	// &.header-nav-fixed
	// 	@media all and (max-width: $mobileWidth)
 }	// 		box-shadow: none






















