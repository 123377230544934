.button {
    position: fixed;
    left: 100px;
    bottom: 150px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: linear-gradient(#f1dff4, #fefcfe);
    display: none;
    z-index: 1;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    @media screen and (orientation: landscape) {
        left: initial;
        top: initial;
        right: 20px;
        bottom: 80px; }

    @media screen and (max-width: 650px) and (orientation: portrait) {
        left: 5px; }

    @media screen and (max-width: 480px) {
        left: initial;
        top: initial;
        right: 20px;
        bottom: 80px; }

    .button__text {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 50%;
        margin-left: -10px;
        top: 50%;
        margin-top: -5px;
        border-left: 2px solid;
        border-top: 2px solid;
        transform: rotate(45deg); } }


