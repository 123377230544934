.last-in-blog {
            background: url('../img/jpeg/sky.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            padding-top: 25px;
            height: auto;
            overflow-y: hidden;
            overflow-x: hidden;
            padding-bottom: 40px;
            .facebook-post {
                    width: 500px;
                    margin-left: auto;
                    margin-right: auto;
                    height: inherit;
                    @media screen and (max-width: 480px) {
                        margin-left: auto;
                        margin-right: auto;
                        width: 100%;
                        height: auto; } }
            h2 {
                margin-top: 35px;
                font-family: Arial, sans-serif, futurademi;
                font-style: normal;
                font-weight: normal;
                font-size: 25px;
                text-align: center;
                @media screen and (max-width: 480px) {
                    font-size: 24px; } }
            .posts {
                width: 905px;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                margin-top: 40px;
                @media screen and (max-width: 920px) {
                    // padding-left: 15px;
                    padding-right: 15px;
                    width: 350px; }
                @media screen and (max-width: 480px) {
                     margin-top: 100px; }
                @media screen and (max-width: 350px) {
                    padding-left: 0px;
                    padding-right: 0px;
                    width: 100%; }
                .post {
                    width: 100%;
                    height: 170px;
                    box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.25);
                    border-radius: 15px;
                    position: relative;
                    background-color: #ffffff;
                    @media screen and (max-width: 920px) {
                        display: flex;
                        flex-wrap: wrap;
                        height: 400px; }
                    @media screen and (max-width: 350px) {
                        width: 100%; }
                    &:hover {
                        background-color: #F1DFF4; }
                    a {
                        color: #000000;
                        .post-img {
                            width: 240px;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            @media screen and (max-width: 920px) {
                                position: relative;
                                left: initial;
                                display: inline-block;
                                vertical-align: top;
                                height: 45%;
                                flex-wrap: wrap;
                                width: 100%; }
                            @media screen and (max-width: 350px) {
                                width: 100%; }
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 15px 0px 0px 15px;
                                @media screen and (max-width: 920px) {
                                    border-radius: 15px 15px 0px 0px; }
                                @media screen and (max-width: 350px) {
                                    width: 100%; } } }
                        .post-text {
                            position: absolute;
                            right: 0;
                            padding-top: 30px;
                            height: 100%;
                            width: 625px;
                            @media screen and (max-width: 920px) {
                                right: initial;
                                bottom: 0;
                                padding-top: 0px;
                                height: 55%;
                                width: 100%;
                                text-align: center;
                                padding-left: 0px; }
                            @media screen and (max-width: 350px) {
                                width: 100%; }
                            h3 {
                                font-family: Arial, sans-serif, futurademi;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 19px;
                                @media screen and (max-width: 920px) {
                                    margin-top: 10px;
                                    width: 280px;
                                    margin-left: auto;
                                    margin-right: auto; }
                                @media screen and (max-width: 480px) {
                                    font-size: 19px; }
                                @media screen and (max-width: 350px) {
                                    width: 200px; } }


                            p {
                                width: 420px;
                                display: block;
                                height: 40px;
                                font-family: Arial, futuralight;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 15px;
                                line-height: 15px;
                                margin-top: 5px;
                                @media screen and (max-width: 920px) {
                                    width: 100%;
                                    height: auto;
                                    font-size: 17px;
                                    margin-left: auto;
                                    margin-right: auto;
                                    padding-left: 20px;
                                    padding-right: 20px; }
                                @media screen and (max-width: 480px) {
                                    line-height: 19px;
                                    margin-top: 5px; } }

                            .blog-link {
                                width: 180px;
                                margin-top: 35px;
                                display: flex;
                                @media screen and (max-width: 920px) {
                                    position: absolute;
                                    bottom: 20px;
                                    left: 50%;
                                    margin-left: -90px; }
                                @media screen and (max-width: 480px) {
                                    width: 150px; }
                                .read-more {
                                    font-family: Arial, futuralight;
                                    font-style: normal;
                                    font-weight: bold;
                                    color: #000000;
                                    width: 145px;
                                    @media screen and (max-width: 480px) {
                                        font-size: 14px;
                                        width: 135px;
                                        margin-left: auto;
                                        margin-right: auto; } }
                                .read-more-arrow {
                                    width: 6px;
                                    height: 6px;
                                    border-left: 2px solid #000000;
                                    border-top: 2px solid #000000;
                                    transform: rotate(135deg);
                                    margin-top: 5px; } } } } }

                .posts-middle-line {
                    margin-top: 40px;
                    margin-bottom: 40px;
                    border: 1px solid rgba(193, 187, 187, 0.2);
                    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25); } } }
