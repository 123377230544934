.about {
            margin-top: 35px;
            width: 620px;
            height: 548px;
            margin-left: auto;
            margin-right: auto;
            @media screen and (max-width: 620px) {
                width: 100%;
                overflow-x: hidden;
                overflow-y: hidden; }
            @media screen and (max-width: 480px) {
                height: auto; }
            h2 {
                font-family: Arial, sans-serif, futurademi;
                font-style: normal;
                font-weight: normal;
                text-transform: uppercase;
                margin-left: auto;
                margin-right: auto;
                width: 160px;
                font-size: 40px;
                margin-bottom: 30px;
                @media screen and (max-width: 480px) {
                    font-size: 22px;
                    width: auto;
                    text-align: center;
                    margin-bottom: 15px; } }

            .olimpic {
                position: relative;
                height: 122px;
                @media screen and (max-width: 480px) {
                    display: flex;
                    flex-wrap: wrap;
                    height: 185px; }
                .olimpic-ico {
                    position: absolute;
                    left: 0px;
                    width: 135px;
                    height: 100%;
                    background-image: url('../img/png/sprite.png');
                    background-position: 5px -5px;
                    @media screen and (max-width: 480px) {
                        width: 70px;
                        position: static;
                        margin-left: auto;
                        margin-right: auto;
                        background-position: -827px -15px;
                        height: 35%; } }
                .olimpic-text {
                    width: 503px;
                    position: absolute;
                    right: 0px;
                    padding-left: 45px;
                    font-family: Arial, futuralight;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 19px;
                    line-height: 25px;
                    @media screen and (max-width: 620px) {
                        width: 400px;
                        padding-left: 0px;
                        text-align: left; }
                    @media screen and (max-width: 520px) {
                        font-size: 17px;
                        padding-left: 4%; }
                    @media screen and (max-width: 480px) {
                        position: static;
                        text-align: center;
                        padding-left: 20px;
                        padding-right: 20px;
                        width: auto;
                        height: auto; } } }
            .hands {
                position: relative;
                height: 122px;
                @media screen and (max-width: 480px) {
                    display: flex;
                    flex-wrap: wrap;
                    height: 155px; }
                .hands-ico {
                    position: absolute;
                    left: 0px;
                    width: 135px;
                    height: 100%;
                    background-image: url('../img/png/sprite.png');
                    background-position: -120px -3px;
                    @media screen and (max-width: 480px) {
                        width: 70px;
                        position: static;
                        margin-left: auto;
                        margin-right: auto;
                        background-position: -918px -8px;
                        height: 45%; } }
                .hands-text {
                    width: 503px;
                    position: absolute;
                    right: 0px;
                    padding-left: 45px;
                    font-family: Arial, futuralight;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 19px;
                    top: 35%;
                    left: 60%;
                    transform: translate(-50%, -50%);
                    @media screen and (max-width: 620px) {
                        width: 400px; }
                    @media screen and (max-width: 600px) {
                        padding-left: 6%; }
                    @media screen and (max-width: 570px) {
                         padding-left: 4%; }
                    @media screen and (max-width: 520px) {
                        font-size: 17px; }
                    @media screen and (max-width: 480px) {
                        position: static;
                        text-align: center;
                        margin-left: auto;
                        margin-right: auto;
                        padding-left: 20px;
                        padding-right: 20px;
                        width: 420px;
                        transform: none; } } }
            .run {
                position: relative;
                height: 122px;
                width: 100%;
                @media screen and (max-width: 480px) {
                    display: flex;
                    flex-wrap: wrap;
                    height: 250px; }
                .run-ico {
                    position: absolute;
                    left: 0px;
                    width: 135px;
                    height: 100%;
                    background-image: url('../img/png/sprite.png');
                    background-position: -245px -6px;
                    @media screen and (max-width: 480px) {
                        width: 70px;
                        position: static;
                        margin-left: auto;
                        margin-right: auto;
                        background-position: -1025px -5px;
                        height: 30%; } }
                .run-text {
                    width: 503px;
                    position: absolute;
                    right: 0px;
                    padding-left: 45px;
                    font-family: Arial, futuralight;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 19px;
                    line-height: 25px;
                    @media screen and (max-width: 620px) {
                        width: 400px;
                        padding-left: 0px; }
                    @media screen and (max-width: 520px) {
                        font-size: 17px;
                        line-height: 25px;
                        padding-left: 6%; }
                    @media screen and (max-width: 480px) {
                        position: static;
                        text-align: center;
                        margin-left: auto;
                        margin-right: auto;
                        padding-left: 20px;
                        padding-right: 20px;
                        width: auto;
                        transform: none; } } }
            .idea {
                position: relative;
                height: 122px;
                @media screen and (max-width: 480px) {
                    display: flex;
                    flex-wrap: wrap;
                    height: 170px;
                    margin-top: 15px; }
                .idea-ico {
                    position: absolute;
                    left: 0px;
                    width: 135px;
                    height: 100%;
                    background-image: url('../img/png/sprite.png');
                    background-position: -360px -6px;
                    @media screen and (max-width: 480px) {
                        width: 70px;
                        position: static;
                        margin-left: auto;
                        margin-right: auto;
                        background-position: -1122px -5px;
                        height: 45%; } }
                .idea-text {
                    width: 503px;
                    position: absolute;
                    right: 0px;
                    padding-left: 45px;
                    font-family: Arial, futuralight;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 19px;
                    top: 35%;
                    left: 60%;
                    transform: translate(-50%, -50%);
                    @media screen and (max-width: 620px) {
                        width: 400px; }
                    @media screen and (max-width: 600px) {
                        padding-left: 6%; }
                    @media screen and (max-width: 570px) {
                         padding-left: 4%; }
                    @media screen and (max-width: 520px) {
                        font-size: 17px; }
                    @media screen and (max-width: 480px) {
                        position: static;
                        text-align: center;
                        margin-left: auto;
                        margin-right: auto;
                        padding-left: 20px;
                        padding-right: 20px;
                        width: 420px;
                        transform: none; } } } }
